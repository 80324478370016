<template>
<div>
  <Loading :isLoading="isLoading" />
  <div>
    <p style="padding: 10px 10px 0px; font-size: 20px; color: rgb(129, 0, 66); font-weight: 700; font-family: Lato;">Solicitudes Nuevas</p>
  </div>
  
  <div class="py-2">
    <TableComponent :key="render" :columns="columns" :rows="rows" :rangeInput="true" :exportInput="true" />
  </div>
</div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import Loading from '@/components/Loading/VueLoading.vue';

import TableComponent from "@/components/Forms/Tables/TableComponent";
import {getTokenDecoden} from "@/helpers/tokenauth";
import Affiliated from '@/classes/Affiliated.js';
import AdmissionRequest from "@/classes/AdmissionRequest";

import { useRouter } from 'vue-router';

export default {
  name: 'SolicitudesEnProceso',
  setup(){
    const isLoading = ref(true);
    const apiToken = ref("");
    const personId = ref("");
    const requests = ref([]);
    const requestType = ref("");
    const associates = ref([]);
    const userId = ref(0);
    const assignmentType = ref("Reasignar");
    const affiliatedObject = ref(null);
    const admissionObject = ref(null);
    const columns = ref([]);
    const rows = ref([]);
    const router = useRouter();

    let app = getCurrentInstance();
    let render = ref(false);

    onMounted(async () => {
      app = app.appContext.config.globalProperties;
      affiliatedObject.value = new Affiliated();
      admissionObject.value = new AdmissionRequest();
      loadInfo();
      await getAssociates();
      await getRequests();
      isLoading.value = false;
    });

    const assign = async (idRequest) => {
      var swal = require('sweetalert2');
      isLoading.value = true;

      await admissionObject.value.assingAnalystLogged(apiToken.value, personId.value, idRequest).then(() => {
        getRequests().then(() => {
          swal.fire({
            title: "Aviso",
            text: "La solicitud fue asignada a un asociado correctamente",
            icon: "success",
            confirmButtonColor: '#FEB72B',
          });
          isLoading.value = false;
        });
      }).catch(err => {
        console.log("Error", err);
        isLoading.value = false;
      });
    }

    const getRequests = async() => {
      render.value = false;
      await affiliatedObject.value.getNewRequests(apiToken.value, "pendiente_de_revision").then((response) => {
        
        if(response.data.response.admission_request == undefined){
          getColumns(); 
          isLoading.value = false;
          render.value = true;
          return;
        }
       
        let fields = getFinalArrayList(response.data.response.admission_request);
        getColumns(); 
        rows.value = fields.reverse();
             
        render.value = true;
        isLoading.value = false;
      });
    }

    const getAssociates = async () =>{
      affiliatedObject.value.getAllAnalysts(apiToken.value).then(response => {
        associates.value = response.data.response.analysts;
      });
    }

    const getColumns = () => {
      columns.value = [
        { headerName: "RFC", field: "rfc"},
        { headerName: "Nombre", field: "name" },
        { headerName: "Teléfono", field: "phone" },
        { headerName: "Correo", field: "email" },
        { headerName: "Monto a ahorrar", field: "savingAmount" },
        { headerName: "Puesto/Ocupación", field: "occupation" },
        { headerName: "Fecha de registro", field: "date" },
        { headerName: "Fecha de pago", field: "dateEnrollPaymentFee" },
        { headerName: "Metodo de pago", field: "methodEnrollPaymentFee" },
        { headerName: "Acciones", field: "view", filter: false, cellRenderer: params => {
          console.log("Params", params);
          const input = document.createElement("label");
          input.innerText = "Asignar";
          input.className += "bg-cherryColor text-white focus:bg-blue-700 px-10 py-1.5 mx-auto rounded-md cursor-pointer text-base";
          input.addEventListener("click", e => {
            e.preventDefault();
            assign(params.value);
          });
          return input;
        }}
      ];
    }

    const viewRequest = (value) => {
      router.push({path: value})
    }

    const loadInfo = () => {
      var auth = getTokenDecoden();
      apiToken.value = sessionStorage.getItem("login");
      personId.value  = auth.obj.personnel_info['id'];
      userId.value  = auth['id'];
    }
    
    const getFinalArrayList = (array) => {
      let finalArray = [];
      
      for (let i = 0; i < array.length; i++) {
        let contact_info = convertListToArray(array[i].admission_information.contact_info_attributes);

        var occupation = array[i].admission_information.work_info_attributes.occupation;
        var laboralStatus = array[i].admission_information.work_info_attributes.laboral_status;
        occupation = app.$filters.formatCamelize(laboralStatus.toLowerCase() == "jubilado" ? "jubilado" : occupation);

        finalArray.push({
          rfc: array[i].admission_information.rfc,
          name: app.$filters.formatCamelize(`${array[i].admission_information.name} ${array[i].admission_information.paternal_name}`),
          phone: app.$filters.formatPhone(contact_info.filter(({contact_type}) => contact_type === 'celular').length > 0 ? contact_info.filter(({contact_type}) => contact_type === 'celular')[0].value : "1111111111"),
          email: contact_info.filter(({contact_type}) => contact_type === 'correo').length > 0 ? contact_info.filter(({contact_type}) => contact_type === 'correo')[0].value : "correo@gmail.com",
          savingAmount: app.$filters.formatCurrency(array[i].admission_information.saving_registration_attributes.amount == "" ? "0" : array[i].admission_information.saving_registration_attributes.amount),
          occupation: occupation,
          status : array[i].status,
          date : app.$filters.formatDate(array[i].created_at),
          asignedName: array[i].status == "en_revision" ? getAsignedUser(array[i].admission_analyst_id) : "",
          view: array[i].id,
          dateEnrollPaymentFee: app.$filters.formatDate(array[i].admission_information.enrollment_fee_payment_date),
          methodEnrollPaymentFee: array[i].admission_information.enrollment_fee_method,
          analysts: {
            admission_analyst_id: array[i].admission_analyst_id,
            admission_request_id:array[i].id
          },
          action: {
            admission_analyst_id: array[i].admission_analyst_id,
            admission_request_id:array[i].id
          }
        })
      }
      return finalArray;
    }

    const convertListToArray = (list) => {
      let index = [];
      let array = [];
      for (var i in list) {
        index.push(i);
      }

      for (let i = 0; i < index.length; i++) {
        array.push(list[index[i]]);
      }

      return array;
    }

    const getAsignedUser = (value) => {
      var asociate = associates.value.filter((associate) => associate.id == value)
      if(asociate.length > 0){
        return `${asociate[0].name.toUpperCase()} ${asociate[0].paternal_name.toUpperCase()}`
      }else{
        return ""
      }
    }


    return {
      isLoading,
      apiToken,
      personId,
      requests,
      requestType,
      associates,
      userId,
      assignmentType,
      columns,
      rows,
      render,
      viewRequest
    }
  },
  components: {
    Loading,
    TableComponent
  },
}

</script>